const isMobile = window.innerWidth < 1280;

// lenis
const lenis = new Lenis({
  lerp: 0.06,
});

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}

requestAnimationFrame(raf);

lenis.on("scroll", ScrollTrigger.update);

gsap.ticker.add((time) => {
  lenis.raf(time * 1000);
});

jQuery(document).ready(function ($) {
  setTimeout(() => {
    $(".submenu").css("visibility", "visible");
  }, 1000);

  // custom cursor
  var cursorHtml = `
  <div class="cursor">
    <div class="cursor-text" data-c="drag"></div>
  </div>`;
  $("body").append(cursorHtml);
  var cursor = {
    delay: 1,
    _x: 0,
    _y: 0,
    endX: window.innerWidth / 2,
    endY: window.innerHeight / 2,
    $cursor: document.querySelector(".cursor"),
    $html: document.querySelector("html"),

    init: function () {
      this.setupEventListeners();
      // this.animateDotOutline();
    },

    setupEventListeners: function () {
      var self = this;

      if (document.querySelector(".block-product-gallery")) {
        document
          .querySelectorAll(".block-product-gallery .swiper-slide")
          .forEach(function (el) {
            el.addEventListener("mouseenter", function (e) {
              self.$html.dataset.cursorType = "drag";
            });
            el.addEventListener("mouseleave", function () {
              self.$html.dataset.cursorType = "none";
            });
          });
      }

      document.addEventListener("mousemove", function (e) {
        self.endX = e.clientX;
        self.endY = e.clientY;
        // self.$cursor.style.top = self.endY + "px";
        // self.$cursor.style.left = self.endX + "px";
        gsap.to(self.$cursor, {
          top: self.endY,
          left: self.endX,
          duration: 0.2,
        });
      });

      window.addEventListener("load", function (e) {
        self.endX = e.clientX;
        self.endY = e.clientY;
        self.$cursor.style.top = self.endY + "px";
        self.$cursor.style.left = self.endX + "px";
      });
    },

    animateDotOutline: function () {
      var self = this;

      self._x += (self.endX - self._x) / self.delay;
      self._y += (self.endY - self._y) / self.delay;
      self.$cursor.style.top = self._y + "px";
      self.$cursor.style.left = self._x + "px";

      requestAnimationFrame(this.animateDotOutline.bind(self));
    },
  };
  cursor.init();

  //headroom
  var header = document.querySelector(".header");
  var headroom = new Headroom(header, {
    offset: 40,
  });
  headroom.init();

  // in-view detect
  function inViewDetect() {
    const blocks = document.querySelectorAll(
      ".in-view-detect, .fade-up-wrapper > *, .fade-in-wrapper > *"
    );
    const blocksLg = document.querySelectorAll(".in-view-detect-lg");

    [].forEach.call(blocks, ($item) => {
      function onScroll() {
        let delta =
          $item.getBoundingClientRect().top - window.innerHeight <=
            ($item.offsetHeight * -1) / 4 &&
          !$item.classList.contains("in-view");

        if (delta) {
          setTimeout(() => {
            $item.classList.remove("in-view-detect");
            $item.classList.add("in-view");
          }, 200);
        }
      }
      onScroll();

      lenis.on("scroll", (e) => {
        onScroll();
      });
    });

    [].forEach.call(blocksLg, ($itemLg) => {
      function isLargeElementInViewport() {
        var rect = $itemLg.getBoundingClientRect();
        let delta =
          (rect.top <=
            (window.innerHeight || document.documentElement.clientHeight) &&
            rect.bottom >=
              (window.innerHeight || document.documentElement.clientHeight) -
                (window.innerHeight || document.documentElement.clientHeight) /
                  2 &&
            rect.right <=
              (window.innerWidth || document.documentElement.clientWidth)) ||
          (rect.top < 0 &&
            rect.bottom <=
              (window.innerHeight || document.documentElement.clientHeight) -
                (window.innerHeight || document.documentElement.clientHeight) /
                  2);

        if (delta) {
          setTimeout(() => {
            $itemLg.classList.remove("in-view-detect-lg");
            $itemLg.classList.add("in-view");
          }, 400);
        }
      }

      isLargeElementInViewport();

      lenis.on("scroll", (e) => {
        isLargeElementInViewport();
      });
    });
  }
  inViewDetect();

  // hover init
  (function () {
    const btnsAnim = $(".btn, .btn-search");

    if (!btnsAnim.length) return;

    btnsAnim.each(function () {
      $(this).on("mouseenter", function () {
        $(this).addClass("hover-init");
      });
    });
  })();

  // notice bar
  !(function () {
    var topbar = $(".topbar-wrapper");
    if (topbar.length > 0) {
      var topbar_height = topbar.outerHeight(),
        topbar_pos = 0;

      if ($(".has-scroll-indicator").length) {
        topbar_height = topbar.outerHeight() + 4;
        topbar_pos = 4;
      }

      setTimeout(function () {
        gsap.to(topbar, {
          opacity: 1,
          y: topbar_pos,
          ease: "none",
        });
        gsap.to(".header", {
          paddingTop: topbar_height,
          ease: "none",
        });
        gsap.to(".global-wrapper", {
          paddingTop: topbar_height,
          ease: "none",
        });
        document
          .querySelector("body")
          .style.setProperty("--header-top", -topbar_height + "px");
      }, 5000);

      $(window).on("resize", function () {
        var topbar_height = topbar.outerHeight();
        $(".global-wrapper").css("padding-top", topbar_height);
        document
          .querySelector("body")
          .style.setProperty("--header-top", -topbar_height + "px");
      });
    }
  })();

  // header theme
  if (document.querySelector(".bg-secondary:not(.topbar-wrapper)")) {
    document
      .querySelectorAll(".bg-secondary:not(.topbar-wrapper)")
      .forEach((bg) => {
        var className = "theme-light";
        ScrollTrigger.create({
          trigger: bg,
          start: "top 50px",
          end: "bottom 20px",
          onEnter: function () {
            document.querySelector(".header").classList.add(className);
          },
          onLeave: function () {
            document.querySelector(".header").classList.remove(className);
          },
          onEnterBack: function () {
            document.querySelector(".header").classList.add(className);
          },
          onLeaveBack: function () {
            document.querySelector(".header").classList.remove(className);
          },
        });
      });
  }

  //content nav
  if (document.querySelector(".content-menu")) {
    function buildContentAnchors(index, heading) {
      var a = $("<a class='btn btn-white'>");
      a.append($("<span class='btn-label-wrap'>"));
      a.find(".btn-label-wrap").append($("<span class='btn-label'>"));
      var btnArrow = `<span class="btn-arrow">
              <svg xmlns="http://www.w3.org/2000/svg" width="10.121" height="5.811" viewBox="0 0 10.121 5.811"><path d="M9,13.5l4,4,4-4" transform="translate(-7.939 -12.439)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" /></svg>
            </span>`;
      a.append(btnArrow);
      var name = "section" + index;
      $(heading).attr("id", name);
      a.attr("href", "#" + name);
      a.find(".btn-label")
        .text($(heading).text())
        .attr("data-text", $(heading).text());
      return a;
    }
    var headings = $(".content-source h2");
    var sections = headings.map(function (i, e) {
      var a = buildContentAnchors(i, e);
      var li = $("<li>");
      li.append(a);
      console.log(a);
      $(".content-menu ul").append(li);
      return li;
    });

    $(".content-source h2").waypoint(
      function (direction) {
        if (direction === "down") {
          $(".content-menu .active").removeClass("active");
          var selector = ".content-menu a[href='#" + this.element.id + "']";
          $(selector).parent().addClass("active");
        }
      },
      {
        offset: document.querySelector(".header").clientHeight,
      }
    );

    $(".content-source h2").waypoint(
      function (direction) {
        if (direction === "up") {
          $(".content-menu .active").removeClass("active");
          var selector = ".content-menu a[href='#" + this.element.id + "']";
          $(selector).parent().prev().addClass("active");
        }
      },
      {
        offset: document.querySelector(".header").clientHeight,
      }
    );

    $(".content-menu a").on("click", function (e) {
      e.preventDefault();
      var dest = document.querySelector($(this).attr("href"));
      lenis.scrollTo(dest, {
        duration: 1,
        offset: document.querySelector(".header").clientHeight * -1,
      });
    });
  }

  // article-navigation
  if (document.querySelector(".article-nav")) {
    function buildArticleAnchors(index, heading) {
      var a = $("<a class='btn btn-light'>");
      a.append($("<span class='btn-label-wrap'>"));
      a.find(".btn-label-wrap").append($("<span class='btn-label'>"));
      var btnArrow = `<span class="btn-arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="10.121" height="5.811" viewBox="0 0 10.121 5.811"><path d="M9,13.5l4,4,4-4" transform="translate(-7.939 -12.439)" fill="none" stroke="#0098DB" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></svg>
          </span>`;
      a.append(btnArrow);
      var name = "section" + index;
      $(heading).attr("id", name);
      a.attr("href", "#" + name);
      a.find(".btn-label")
        .text($(heading).text())
        .attr("data-text", $(heading).text());
      return a;
    }
    var headings = $(".article-content h2");
    var sections = headings.map(function (i, e) {
      var a = buildArticleAnchors(i, e);
      var li = $("<li>");
      li.append(a);
      $(".article-nav ul").append(li);
      return li;
    });

    $(".article-content h2").waypoint(
      function (direction) {
        if (direction === "down") {
          $(".article-nav .active").removeClass("active");
          var selector = ".article-nav a[href='#" + this.element.id + "']";
          $(selector).parent().addClass("active");
        }
      },
      {
        offset: document.querySelector(".header").clientHeight,
      }
    );

    $(".article-content h2").waypoint(
      function (direction) {
        if (direction === "up") {
          $(".article-nav .active").removeClass("active");
          var selector = ".article-nav a[href='#" + this.element.id + "']";
          $(selector).parent().prev().addClass("active");
        }
      },
      {
        offset: document.querySelector(".header").clientHeight,
      }
    );

    $(".article-nav a").on("click", function (e) {
      e.preventDefault();
      var dest = document.querySelector($(this).attr("href"));
      lenis.scrollTo(dest, {
        duration: 1,
        offset: document.querySelector(".header").clientHeight * -1,
      });
    });
  }

  // swiper
  if ($(".banner-home .swiper").length) {
    var carousel1 = new Swiper(".banner-home .swiper", {
      speed: 1500,
      loop: true,
      spaceBetween: 0,
      slidesPerView: 1,
      disableOnInteraction: false,
      autoplay: {
        delay: 6000,
      },
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      pagination: {
        el: ".banner-home .swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        1024: {
          allowTouchMove: false,
        },
      },
      on: {
        init: function () {
          ScrollTrigger.refresh();
          $(".banner-home .swiper-slide-active .banner-text").addClass(
            "active"
          );
          var bannerTextHeight = 0;
          $(".banner-home  .banner-text").each(function () {
            var h = $(this).outerHeight();
            if (h > bannerTextHeight) {
              bannerTextHeight = h;
            }
          });
          $(".banner-home .swiper-pagination").css(
            "top",
            bannerTextHeight + 50
          );
        },
        slideChangeTransitionStart: function () {
          $(".banner-home .banner-text.active").removeClass("active");
        },
        slideChangeTransitionEnd: function () {
          $(".banner-home .swiper-slide-active .banner-text").addClass(
            "active"
          );
        },
      },
    });

    $(window).on("resize", function () {
      var bannerTextHeight = 0;
      $(".banner-home .banner-text").each(function () {
        var h = $(this).outerHeight();
        if (h > bannerTextHeight) {
          bannerTextHeight = h;
        }
      });
      $(".banner-home .swiper-pagination").css("top", bannerTextHeight + 50);
    });
  }

  if ($(".block-blog-slider").length) {
    const swiperBlog = new Swiper(".block-blog-slider .swiper", {
      loop: true,
      centeredSlides: true,
      slidesPerView: 1.26,
      spaceBetween: 14,
      navigation: {
        nextEl: ".block-blog-slider .swiper-button-next",
        prevEl: ".block-blog-slider .swiper-button-prev",
      },
      breakpoints: {
        576: {
          slidesPerView: "auto",
          spaceBetween: 14,
        },
        1200: {
          slidesPerView: "auto",
          spaceBetween: 32,
        },
      },
      on: {
        init: function () {
          ScrollTrigger.refresh();
        },
      },
    });
  }

  if ($(".block-testimonials").length) {
    const swiperTestimonials = new Swiper(".block-testimonials .swiper", {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 32,
      navigation: {
        nextEl: ".block-testimonials .swiper-button-next",
        prevEl: ".block-testimonials .swiper-button-prev",
      },
      on: {
        init: function () {
          ScrollTrigger.refresh();
        },
      },
    });
  }

  if ($(".block-product-gallery").length) {
    const swiperGallery = new Swiper(".block-product-gallery .swiper", {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 10,
      initialSlide: 1,
      navigation: {
        nextEl: ".block-product-gallery .swiper-button-next",
        prevEl: ".block-product-gallery .swiper-button-prev",
      },
      pagination: {
        el: ".block-product-gallery .swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        1440: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
      },
      on: {
        init: function (swiper) {
          ScrollTrigger.refresh();
        },
      },
    });
  }

  if ($(".block-product-carousel").length) {
    const swiperCarousel = new Swiper(".block-product-carousel .swiper", {
      loop: false,
      slidesPerView: 1.26,
      spaceBetween: 14,
      navigation: {
        nextEl: ".block-product-carousel .swiper-button-next",
        prevEl: ".block-product-carousel .swiper-button-prev",
      },
      breakpoints: {
        576: {
          slidesPerView: 2.2,
          spaceBetween: 14,
        },

        1024: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
      on: {
        init: function () {
          ScrollTrigger.refresh();
        },
      },
    });
  }

  // search
  $(".btn-search").on("click", function (e) {
    e.stopPropagation();
    if ($(window).width() < 1024) {
      if ($(this).hasClass("active")) {
        const scrollY = document.body.style.top;
        $("body").css({ position: "initial", top: 0 });
        window.scrollTo(0, parseInt(scrollY || "0") * -1);
        $(this).removeClass("active");
        $(".header").removeClass("nav-opened");
        $(".search-wrapper").removeClass("active");
        headroom.unfreeze();
      } else {
        const scrollY = $(window).scrollTop();
        $("body").css({ position: "fixed", top: -scrollY });
        $(this).addClass("active");
        $(".header").addClass("nav-opened");
        $(".search-wrapper").addClass("active");
        $(".navigation-main").removeClass("active");
        $(".header .navbar-toggler").removeClass("active");
        $(".search-wrapper input").focus();
        headroom.freeze();
      }
    } else {
      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
        $(".search-wrapper").removeClass("active");
      } else {
        $(this).addClass("active");
        $(".search-wrapper").addClass("active");
        $(".search-wrapper input").focus();
      }
    }
  });

  $(document).on("click", function () {
    $(".btn-search").removeClass("active");
    $(".search-wrapper").removeClass("active");
  });

  // menu footer
  const fMenu = $(".footer-menu-columns ul");
  const numListsF = 2;
  const listsF = [];
  for (let i = 0; i < numListsF; i++) {
    listsF.push($("<ul></ul>"));
  }
  fMenu.children().each(function (index) {
    const targetListIndex = index % numListsF;
    $(this).clone().appendTo(listsF[targetListIndex]);
  });
  fMenu.replaceWith(listsF);

  // menu
  const mainMenu = $(".navigation-main>.inner>ul>li:first-child .submenu ul");
  const numLists = 4;
  const lists = [];
  for (let i = 0; i < numLists; i++) {
    lists.push($("<ul></ul>"));
  }
  mainMenu.children().each(function (index) {
    const targetListIndex = index % numLists;
    $(this).clone().appendTo(lists[targetListIndex]);
  });
  mainMenu.replaceWith(lists);

  $(".navigation-main-primary li").each(function () {
    if ($(this).find(".submenu").length) {
      $(this).addClass("menu-parent");
      if ($(this).find(".submenu li").length < 6) {
        $(this).css("position", "relative");
      }
    }
  });
  $(".navigation-main-primary .menu-parent > a").each(function () {
    $(this).after($('<span class="icon" />'));
  });

  $(".header .navbar-toggler").on("click", function () {
    if ($(this).hasClass("active")) {
      const scrollY = document.body.style.top;
      $("body").css({ position: "initial", top: 0 });
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
      $(this).removeClass("active");
      $(".header").removeClass("nav-opened");
      $(".navigation-main").removeClass("active");
      headroom.unfreeze();
    } else {
      const scrollY = $(window).scrollTop();
      $("body").css({ position: "fixed", top: -scrollY });
      $(this).addClass("active");
      $(".header").addClass("nav-opened");
      $(".navigation-main").addClass("active");
      $(".search-wrapper").removeClass("active");
      $(".btn-search").removeClass("active");
      headroom.freeze();
    }
  });

  $(".navigation-main .menu-parent .icon").on("click", function (e) {
    if ($(window).width() < 1024) {
      e.preventDefault();
      if ($(this).parent().hasClass("sub-opened")) {
        $(this)
          .parent()
          .removeClass("sub-opened")
          .find(".submenu")
          .slideUp(300);
      } else {
        $(".navigation-main .sub-opened")
          .removeClass("sub-opened")
          .find(".submenu")
          .slideUp(300);
        $(this).parent().addClass("sub-opened").find(".submenu").slideDown(300);
      }
    }
  });

  $(window).on("resize", function () {
    if ($(window).width() >= 1024) {
      $(".navigation-main .sub-opened")
        .removeClass("sub-opened")
        .find(".submenu")
        .slideUp(300);
      const body = document.body;
      const scrollY = body.style.top;
      body.removeAttribute("style");
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
      $(".header").removeClass("nav-opened");
      $(".search-wrapper").removeClass("active");
      $(".btn-search").removeClass("active");
      $(".navigation-main").removeClass("active");
      $(".header .navbar-toggler").removeClass("active");
    }
  });

  $(".navigation-main .menu-parent")
    .on("mouseenter", function () {
      if ($(window).width() >= 1024) {
        $(".search-wrapper").removeClass("active");
        $(".btn-search").removeClass("active");
        $(".header").addClass("nav-opened");
      }
    })
    .on("mouseleave", function () {
      if ($(window).width() >= 1024) {
        $(".header").removeClass("nav-opened");
      }
    });

  $("body").imagesLoaded(function () {
    ScrollTrigger.refresh();
  });

  // video
  const modalVideoEl = document.getElementById("modalVideo");
  if (modalVideoEl) {
    const modalVideo = new bootstrap.Modal("#modalVideo");

    const modalFrame = document.getElementById("videoFrame");

    const modalToggle = document.querySelectorAll(".video-trigger");

    modalToggle.forEach((el) => {
      el.addEventListener("click", function () {
        var source = this.dataset.src;
        modalFrame.setAttribute("src", source);
        modalVideo.show();
        modalFrame.contentWindow.postMessage(
          '{"event":"command","func":"playVideo","args":""}',
          "*"
        );
      });
    });

    modalVideoEl.addEventListener("hidden.bs.modal", function (event) {
      modalFrame.setAttribute("src", "");
    });
  }

  // tooltips
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]'
  );
  const tooltipList = [...tooltipTriggerList].map(
    (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
  );

  // read more
  $(".text-hidden-toggler").on("click", function () {
    var txt1 = "Read more",
      txt2 = "Read less";

    if ($(this).hasClass("active")) {
      $(this)
        .removeClass("active")
        .find(".btn-label")
        .text(txt1)
        .attr("data-text", txt1);
      $(this).prev(".text-hidden").removeClass("active");
    } else {
      $(this)
        .addClass("active")
        .find(".btn-label")
        .text(txt2)
        .attr("data-text", txt2);
      $(this).prev(".text-hidden").addClass("active");
    }

    setTimeout(() => {
      inViewDetect();
    }, 500);
  });
});

function initMap() {
  var stylers = [{featureType:"administrative",elementType:"geometry.fill",stylers:[{visibility:"on"}]},{featureType:"administrative",elementType:"labels.text.fill",stylers:[{color:"#0098DB"}]},{featureType:"landscape",elementType:"all",stylers:[{color:"#F2F2F2"}]},{featureType:"landscape",elementType:"geometry.fill",stylers:[{color:"#FFFFFF"}]},{featureType:"landscape",elementType:"labels.text",stylers:[{color:"#0098DB"}]},{featureType:"landscape",elementType:"labels.text.fill",stylers:[{visibility:"simplified"}]},{featureType:"landscape.natural",elementType:"labels.text.fill",stylers:[{color:"#FF0000"}]},{featureType:"poi",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"road",elementType:"all",stylers:[{saturation:-100},{lightness:45}]},{featureType:"road.highway",elementType:"all",stylers:[{visibility:"simplified"}]},{featureType:"road.local",elementType:"geometry.fill",stylers:[{color:"#F4F4F4"}]},{featureType:"road.local",elementType:"geometry.stroke",stylers:[{color:"#F4F4F4"}]},{featureType:"transit",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"water",elementType:"all",stylers:[{color:"#F5F8FA"},{visibility:"on"}]}];
  

  if (document.getElementById("map1")) {
    let map,
      geocoder,
      mapContainer = document.getElementById("map1"),
      mapImage = mapContainer.getAttribute("data-icon"),
      mapImageActive = mapContainer.getAttribute("data-icon-active"),
      mapAddress = mapContainer.getAttribute("data-address");
    (mapLat = parseFloat(mapContainer.getAttribute("data-lat"))),
      (mapLng = parseFloat(mapContainer.getAttribute("data-lng"))),
      (mapTitle = mapContainer.getAttribute("data-title"));

    map = new google.maps.Map(mapContainer, {
      center: { lat: mapLat, lng: mapLng },
      zoom: 13,
      styles: stylers,
    });

    const contentString =
      '<div id="content">' +
      '<div id="bodyContent">' +
      "<h5>" +
      mapTitle +
      "</h5><p>" +
      mapAddress +
      "</p>" +
      "</div>" +
      "</div>";

    const infowindow = new google.maps.InfoWindow({
      content: contentString,
    });

    let marker = new google.maps.Marker({
      map: map,
      position: { lat: mapLat, lng: mapLng },
      title: mapAddress,
      icon: mapImage,
    });

    marker.addListener("click", () => {
      if (infowindow.getMap()) {
        infowindow.close();
        marker.setIcon(mapImage);
      } else {
        infowindow.open({
          anchor: marker,
          map,
        });
        marker.setIcon(mapImageActive);
      }
    });

    google.maps.event.addListener(map, "click", () => {
      infowindow.close();
      marker.setIcon(mapImage);
    });

    geocoder = new google.maps.Geocoder();
  }

  if (document.querySelector("#map")) {
    let map,
      geocoder,
      mapContainer = document.getElementById("map"),
      mapImage = mapContainer.getAttribute("data-icon"),
      mapImageActive = mapContainer.getAttribute("data-icon-active");
    let markers = [];
    let infowindows = [];

    let mylat = parseFloat(mapContainer.getAttribute('data-lat'));
    let mylng = parseFloat(mapContainer.getAttribute('data-lng'));
    
    let mapCenterLat = -34.67705313046859,
      mapCenterLng = 138.68510819311024,
      mapZoom = 6;

    if (mapContainer.dataset.lat && mapContainer.dataset.lng) {
      mapCenterLat = parseFloat(mapContainer.dataset.lat);
      mapCenterLng = parseFloat(mapContainer.dataset.lng);
      mapZoom = 10;
    }
    map = new google.maps.Map(mapContainer, {
      center: { lat: mapCenterLat, lng: mapCenterLng },
      zoom: mapZoom,
      styles: stylers,
    });
    geocoder = new google.maps.Geocoder();

    function centerMap(lat, lng) {
      map.setCenter({ lat: lat, lng: lng });
    }

    function initializeMapWithMarkers(locations) {
      locations.forEach((location, i) => {
        console.log(location);

        let locName = location.querySelector("h4").innerText,
          locAddress = location.querySelector("ul li:first-child").innerText;

        let coords = {
          lat: parseFloat(location.dataset.lat),
          lng: parseFloat(location.dataset.lng),
        };

        let marker = new google.maps.Marker({
          map: map,
          position: coords,
          title: locName,
          icon: mapImage,
        });

        const infowindow = new google.maps.InfoWindow({
          content:
            '<div id="content">' +
            '<div id="bodyContent">' +
            "<h5>" +
            locName +
            "</h5><p>" +
            locAddress +
            "</p>" +
            "</div>" +
            "</div>",
        });

        markers.push(marker);
        infowindows.push(infowindow);

        marker.addListener("click", () => {
          if (infowindow.getMap()) {
            infowindow.close();
            marker.setIcon(mapImage);
          } else {
            closeAllInfoWindows();
            infowindow.open(map, marker);
            marker.setIcon(mapImageActive);
            document.querySelectorAll(".block-map .item").forEach((item) => {
              if (item.querySelector("h4").innerText == marker.title) {
                console.log(marker.title, item.querySelector("h4").innerText);
                jQuery(".map-address-scroll").mCustomScrollbar(
                  "scrollTo",
                  item
                );
              }
            });
          }
        });
      });

      // Close infowindow when map is clicked
      google.maps.event.addListener(map, "click", () => {
        infowindow.close();
        marker.setIcon(mapImage);
      });

      // address list scroll
      jQuery(".map-address-scroll").mCustomScrollbar({
        mouseWheelPixels: 400,
        callbacks: {
          onTotalScrollOffset: 50,
          onTotalScroll: function () {
            jQuery(".map-address").addClass("scrolled");
          },
          onScrollStart: function () {
            jQuery(".map-address").removeClass("scrolled");
          },
        },
      });
    }

    let locations = document.querySelectorAll(".block-map .item");
    initializeMapWithMarkers(locations);

    const searchInput = document.getElementById("retailerSearchInput");

    if (searchInput) {
      const options = {
        componentRestrictions: { country: "au" },
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false,
      };

      const autocomplete = new google.maps.places.Autocomplete(
        searchInput,
        options
      );

      google.maps.event.addListener(autocomplete, "place_changed", function () {
        var place = autocomplete.getPlace();

        var postcode = "";
        var suburb = "";

        if (place.address_components) {
          for (var i = 0; i < place.address_components.length; i++) {
            var component = place.address_components[i];

            if (component.types.includes("postal_code")) {
              postcode = component.long_name;
            }

            if (component.types.includes("administrative_area_level_1")) {
              suburb = component.short_name;
            }
          }
        }

        let name = place.name;

        jQuery.ajax({
          url: globals.url,
          data: {
            action: "search",
            postcode: postcode,
            suburb: suburb,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          },
          beforeSend: function () {
            jQuery(".map-address-scroll").mCustomScrollbar("destroy");
          },
          success: function (data) {
            jQuery(".map-address-scroll").html(data.html);
            // console.log(data);

            let locations = document.querySelectorAll(".block-map .item");
            initializeMapWithMarkers(locations);
            centerMap(
              place.geometry.location.lat(),
              place.geometry.location.lng()
            );
          },
        });
      });
    }
    
    if (mylat && mylng) {
      
      jQuery.ajax({
        url: globals.url,
        data: {
          action: "search",
          postcode: '0000',
          suburb: 'test',
          lat: mylat,
          lng: mylng,
        },
        beforeSend: function () {
          jQuery(".map-address-scroll").mCustomScrollbar("destroy");
          //alert('desctry');
        },
        success: function (data) {
          jQuery(".map-address-scroll").html(data.html);
          // console.log(data);
          //alert('test');

          let locations = document.querySelectorAll(".block-map .item");
          initializeMapWithMarkers(locations);
          centerMap(
            mylat,
            mylng
          );
        },
      });
    }

    jQuery(document).on("click", ".state-search a", function (e) {
      e.preventDefault();

      let st = jQuery(this).find("span").text();

      jQuery.ajax({
        url: globals.url,
        data: {
          action: "search_state",
          state: st,
        },
        success: function (data) {
          jQuery(".map-address-scroll").html(data);

          let locations = document.querySelectorAll(".block-map .item");
          initializeMapWithMarkers(locations);

          jQuery("html, body").animate(
            {
              scrollTop: jQuery("#map").offset().top,
            },
            1000
          );
        },
      });
      console.log(st);
    });

    

    function closeAllInfoWindows() {
      infowindows.forEach((infowindow) => {
        infowindow.close();
      });
      markers.forEach((marker) => {
        marker.setIcon(mapImage);
      });
    }
  }

  jQuery(document).on("click", ".btn-action", function () {
      let name = jQuery(this).attr("data-name");
      let mail = jQuery(this).attr("data-mail");

      jQuery("#input_4_10").val(name);
      jQuery("#input_4_11").val(mail);
    });
}
